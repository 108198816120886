<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="title" label="等级名称">
						<a-input v-model:value="formState.title" placeholder="请输入等级名称"></a-input>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择" style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['coupon_stored_policy_add']" type="primary" @click="onAdd">添加</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1100 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled === 1 ? '已禁用' : '已启用' }}
						</template>
						<template v-else-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_policy_edit']" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_policy_sync']" @click="onSync(record)">
											<a-menu-item>同步新政策</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_policy_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
		</a-spin>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getCardPolicyList, deleteCardPolicy, syncCardPolicy } from '@/service/modules/coupon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				formState: {
					// organizationId: 0,
					cinemaId: 0,
					title: ''
				},
				searchData: {},
				organizationList: [],
				cinemaAllList: [],
				showModal: false,
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '等级名称',
					dataIndex: 'title'
				}, {
					title: '是否启用',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0
			}
		},
		created() {
			this.getOrganizationList();
			//this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardPolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						type: 3,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onSync(item) {
				this.$confirm({
					title: '提示',
					content: '此操作会将最新设置的卡等级使用政策同步至线上会员卡中，确定同步吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await syncCardPolicy({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('同步成功!');
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onAdd() {
				this.isEdit = false;
				this.isSee = false;
				this.id = 0;
				this.showModal = true;
			},
			onEdit(item) {
				this.isEdit = true;
				this.isSee = false;
				this.id = item.id;
				this.showModal = true;
			},
			onSee(item) {
				this.isEdit = true;
				this.isSee = true;
				this.id = item.id;
				this.showModal = true;
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定要删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteCardPolicy({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
